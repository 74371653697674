import { Router } from 'preact-router';
import Subscribe from '../routes/subscribe';
import Confirm from '../routes/confirm';

const App = () => {
  return (
    <div id="app">
      <main>
        <Router>
          <Subscribe path="/subscribe"/>
          <Confirm path="/subscribe/confirm/:token"/>
        </Router>
      </main>
    </div>
  );
};

export default App;
